exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-srv-as-a-company-newsroom-[category]-[slug]-js": () => import("./../../../src/pages/en/srv-as-a-company/newsroom/[category]/[slug].js" /* webpackChunkName: "component---src-pages-en-srv-as-a-company-newsroom-[category]-[slug]-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview/[...].js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-srv-yrityksena-ajankohtaista-[category]-[slug]-js": () => import("./../../../src/pages/srv-yrityksena/ajankohtaista/[category]/[slug].js" /* webpackChunkName: "component---src-pages-srv-yrityksena-ajankohtaista-[category]-[slug]-js" */),
  "component---src-templates-apartment-image-gallery-js": () => import("./../../../src/templates/apartmentImageGallery.js" /* webpackChunkName: "component---src-templates-apartment-image-gallery-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-taxonomy-js": () => import("./../../../src/templates/articleTaxonomy.js" /* webpackChunkName: "component---src-templates-article-taxonomy-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-cision-article-js": () => import("./../../../src/templates/cisionArticle.js" /* webpackChunkName: "component---src-templates-cision-article-js" */),
  "component---src-templates-city-landing-page-js": () => import("./../../../src/templates/cityLandingPage.js" /* webpackChunkName: "component---src-templates-city-landing-page-js" */),
  "component---src-templates-construction-site-js": () => import("./../../../src/templates/constructionSite.js" /* webpackChunkName: "component---src-templates-construction-site-js" */),
  "component---src-templates-construction-site-news-js": () => import("./../../../src/templates/constructionSiteNews.js" /* webpackChunkName: "component---src-templates-construction-site-news-js" */),
  "component---src-templates-housing-image-gallery-js": () => import("./../../../src/templates/housingImageGallery.js" /* webpackChunkName: "component---src-templates-housing-image-gallery-js" */),
  "component---src-templates-housing-js": () => import("./../../../src/templates/housing.js" /* webpackChunkName: "component---src-templates-housing-js" */),
  "component---src-templates-housing-with-apartments-js": () => import("./../../../src/templates/housingWithApartments.js" /* webpackChunkName: "component---src-templates-housing-with-apartments-js" */),
  "component---src-templates-office-floor-image-gallery-js": () => import("./../../../src/templates/officeFloorImageGallery.js" /* webpackChunkName: "component---src-templates-office-floor-image-gallery-js" */),
  "component---src-templates-office-floor-js": () => import("./../../../src/templates/officeFloor.js" /* webpackChunkName: "component---src-templates-office-floor-js" */),
  "component---src-templates-office-image-gallery-js": () => import("./../../../src/templates/officeImageGallery.js" /* webpackChunkName: "component---src-templates-office-image-gallery-js" */),
  "component---src-templates-office-js": () => import("./../../../src/templates/office.js" /* webpackChunkName: "component---src-templates-office-js" */),
  "component---src-templates-office-with-floors-js": () => import("./../../../src/templates/officeWithFloors.js" /* webpackChunkName: "component---src-templates-office-with-floors-js" */),
  "component---src-templates-parking-js": () => import("./../../../src/templates/parking.js" /* webpackChunkName: "component---src-templates-parking-js" */),
  "component---src-templates-parking-space-image-gallery-js": () => import("./../../../src/templates/parkingSpaceImageGallery.js" /* webpackChunkName: "component---src-templates-parking-space-image-gallery-js" */),
  "component---src-templates-parking-space-js": () => import("./../../../src/templates/parkingSpace.js" /* webpackChunkName: "component---src-templates-parking-space-js" */),
  "component---src-templates-parking-with-park-spaces-js": () => import("./../../../src/templates/parkingWithParkSpaces.js" /* webpackChunkName: "component---src-templates-parking-with-park-spaces-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-taxonomy-term-reference-js": () => import("./../../../src/templates/taxonomyTermReference.js" /* webpackChunkName: "component---src-templates-taxonomy-term-reference-js" */),
  "component---src-templates-taxonomy-term-story-js": () => import("./../../../src/templates/taxonomyTermStory.js" /* webpackChunkName: "component---src-templates-taxonomy-term-story-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

